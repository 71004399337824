import React from 'react';
import { Routes, Route } from 'react-router-dom';
import LandingPage from './components/LandingPage';
import MainLayout from './components/MainLayout';
import ProfilePage from './components/ProfilePage';
import ProtectedRoute from './components/ProtectedRoute';
import LawDirectPage from './components/LawDirectPage';

const App: React.FC = () => {
  return (
    <Routes>
      {/* Public Landing Page */}
      <Route path="/" element={<LandingPage />} />

      {/* Protected Main Layout */}
      <Route path="/dashboard" element={<ProtectedRoute element={<MainLayout />} />} />
      <Route path="/profile" element={<ProtectedRoute element={<ProfilePage />} />} />
      <Route path="/lawdirect" element={<ProtectedRoute element={<LawDirectPage />} />} />
    </Routes>
  );
};

export default App;
